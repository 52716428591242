.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}


@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  background: #dbdbd9;
  display: block;
  overflow-x: hidden;
}