.highlighted {
    background-color: white;
    color: green;
    padding: 0;
    margin: 0;
    transition: 0.3s;
}
  

.highlighted.active {
    background-color: rgb(235, 180, 180);
}